import type { MetaFunction } from '@remix-run/react';
import { useState } from 'react';

import { EmbeddedPage } from '../components/EmbeddedPage';
import { LearningLandingHeader } from '../components/Training/Landing/Components/LearningLandingHeader';
import { LearningLandingLocationManager } from '../components/Training/Landing/LearningLandingLocationManager';
import { useUser } from '../components/UserContext';
import { isGuest } from '../types/user';
import { LearningStarterLayout } from './learning.starter.client';

export const meta: MetaFunction = () => {
  return [{ title: 'About Us | Luna Park' }];
};

export function Component() {
  return (
    <LearningStarterLayout>
      <Internal />
    </LearningStarterLayout>
  );
}

function Internal() {
  const [user] = useState(useUser());
  const userIsntLoggedIn = !user.id || isGuest(user);
  const lastLanding = LearningLandingLocationManager.GetLandingLocation();
  const logoRedirect = `/learning/${
    lastLanding === 'main' ? 'starter' : 'pest-control'
  }`;

  return (
    <div className='flex flex-col h-full'>
      <LearningLandingHeader
        userIsntLoggedIn={userIsntLoggedIn}
        source={lastLanding}
        lpLogoRedirect={logoRedirect}
      />
      <div className='flex-1 w-full'>
        <EmbeddedPage src='https://lunapark.com/lp/about-us' title='About Us' />
      </div>
    </div>
  );
}
